import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: "AIzaSyBWsA69KuE4-1zNYr1pq53eCNfXEJh1xnE",
  authDomain: "iserah.com",
  databaseURL: "https://iserah-2e8c1.firebaseio.com",
  projectId: "iserah-2e8c1",
  storageBucket: "iserah-2e8c1.appspot.com",
  messagingSenderId: "667519547634",
  appId: "1:667519547634:web:8ea6dd20130edd072c786b",
  measurementId: "G-V55M7SEMGS"
}

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // firebase.analytics();
  export const auth = firebase.auth();
  // auth.useEmulator("http://localhost:9099");

  export const storage = firebase.storage();
  export const fdb = firebase.firestore();
  // fdb.useEmulator("localhost", 8585);
 firebase.analytics();
export const fb = firebase;

