import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { fb } from "../../firebase-config/firebaseConfig";
import "./InterviewQ.css";

const InterviewQ = ({user}) => {

  useEffect(() => {
    fb.analytics().logEvent("interviewQ_visit");
     
  }, []);

  return (
<div id="interviewQ-cont">
      <div className="interviewQ-head">
        <h2 style={{ marginTop: "0" }}>اسئلة المقابلة الشخصية</h2>
      </div>
    {user.activation && user.typofsub === "إشتراك سنة" || user.activation && user.typofsub === "إشتراك 3 اشهر"  ? <div className="interviewQ-body">
        <div className="q-box">
          <h3>1. تكلم عن نفسك</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong> 
            من المرجح أن یبدأ القائمون على المقابلات الشخصیة بسؤالك عن نفسك
            وخلفیتك للتعرف علیك. ابدأ بإعطائھم لمحة عامة عن منصبك الحالي أو
            أنشطتك الحالیة، ثم قدم أھم الملامح المھمة وذات الصلة عن خلفیتك والتي
            تجعلك أكثر تأھیلاً للوظیفة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong> 
            "حالیا، أعمل كسكرتیر لمدیر المبیعات. من خلال خبرتي التي تبلغ ٦ سنوات
            كسكرتیر لمدیر المبیعات، طورت القدرة على توقع العقبات وإنشاء خطط
            بدیلة فعالة. أعظم قیمة بالنسبة لي ھي قدرتي على العمل بشكل مستقل،
            وتوفیر وقت للتركیز على احتیاجات العمل. و باعتباري شخصا مھتما
            بالتفاصیل و التنظیم، فإنني أتأكد من أن كل یوم لدي خطة واضحة وأن كل
            خطة یتم إنجازھا باحترافیة عالیة".
          </p>
        </div>
        <div className="q-box">
          <h3>2. كیف تصف نفسك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            عندما یطلب منك المحاور التحدث عن نفسك، فإنه یبحث عن معلومات حول
            كیفیة توافق صفاتك وخصائصك مع المھارات التي یعتقد أنھا مطلوبة للنجاح
            في الوظیفة. إذا كان ذلك ممكنا، قم بتضمین نتائج قابلة للقیاس الكمي
            لتوضیح كیفیة استخدامك لأفضل سماتك لتحقیق النجاح.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "أود أن أقول إنه بصفتي مدرس لغة عربیة، فأنا متیقظ وملتزم بضمان بیئات
            تعلیمیة آمنة ومأمونة ومنظمة للطلاب. في تقییمي الأخیر للتطویر التدریس
            عن بعد، تلقیت 99٪ مقابل متوسط الفریق، والذي كان عند حوالي 97٪ على
            مدى السنوات الثلاث الماضیة. أحب أن أكون دقیقا وأبحث دائما عن أحدث
            الطرق والتقنیات التعلیمیة لتطویر طریقة تعلیم الطلاب. كثیرا ما أقدم
            اقتراحات للإدارة حول التحسینات والتغییرات التعلیمیة التي من الممكن
            أن ترفع مستوى فھم الطلاب للدروس".
          </p>
        </div>

        <div className="q-box">
          <h3>3. ما الذي یجعلك ممیزا؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            غالبا ما یطرح أصحاب العمل ھذا السؤال لتحدید سبب كونك مؤھلاً أكثر من
            المرشحین الآخرین الذین تجري مقابلات معھم. للإجابة، ركز على سبب فائدة
            توظیفك لصاحب العمل. نظرا لأنك لا تعرف المتقدمین الآخرین، فقد یكون من
            الصعب التفكیر في إجابتك فیما یتعلق بھم. إن ما یجعلك المرشح المناسب
            ھو ابراز خبراتك بشكل جید والتي سیتیح لأصحاب العمل معرفة سبب جعل
            سماتك ومؤھلاتك مرشحا مناسبا.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "مایجعلني فریًدا ھو تجربتي في قضاء أربع سنوات في البیع بالتجزئة.
            نظًرا لأنني أمتلك￼ خبرة مباشرة في استقبال أسئلة واستفسارات المتسوقین
            وتعلیقاتھم وشكاویھم، فأنا أعرف ما یریده العملاء. أعرف ما یلزم لخلق
            تجربة إیجابیة للمستھلك لأنني مررت بھذا التفاعل المباشر من خلال العمل
            مع المستھلكین شخصیا".
          </p>
        </div>

        <div className="q-box">
          <h3>4. لماذا ترید أن تعمل ھنا؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            غالبا ما یطرح المحاورون ھذا السؤال كطریقة لتحدید ما إذا كنت قد
            استغرقت وقتا للبحث عن الشركة ومعرفة سبب رؤیتك لنفسك مناسبا فیھا.
            أفضل طریقة للتحضیر لھذا السؤال ھي القیام بواجبك والتعرف على المنتجات
            والخدمات والرسالة والتاریخ والثقافة في مكان العمل ھذا. في إجابتك،
            اذكر جوانب الشركة التي تروق لك وتتوافق مع أھدافك المھنیة. اشرح لماذا
            تبحث عن ھذه الأشیاء في صاحب العمل.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "إن مھمة الشركة ھي الوصل و تجاوز توقعات العملاء من حیث تقدیم الخدمات
            أو السلع. وعلى ھذا الأساس، ارغب في فرصة عمل مع شركة تھتم بعملائھا
            لأن ذلك یعكس اھتمام الشركة بموظفیھا. في رأي، الترشح في شركة ذات بیئة
            عمل إیجابیة وقیم تتماشى مع بیئتي الشخصیة یمثل أولویة خلال بحثي عن
            وظیفة، وتحتل شركتكم المرتبة الأولى في ھذه القائمة ".
          </p>
        </div>

        <div className="q-box">
          <h3>5. ما الذي یثیر اھتمامك في اختیارك لھذه الوظیفة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            مثل السؤال السابق، غالبا ما یقوم مدیرو التوظیف بتضمین ھذا السؤال
            للتأكد من فھمك للدور ومنحك فرصة لتسلیط الضوء على مھاراتك ذات الصلة.
            بالإضافة إلى قراءة الوصف الوظیفي بدقة، قد یكون من المفید مقارنة
            متطلبات الوظیفة بمھاراتك وخبراتك. اختر بعض الأشیاء التي تستمتع بھا
            بشكل خاص أو تتفوق فیھا وركز علیھا في إجابتك.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "كممرضة، إن إحداث تغییر ذي مغزى في حیاة مرضاي وعائلاتھم یحفزني على
            السعي للتمیز في كل ما أقوم به. إنني أتطلع إلى رؤیة رد فعلھم عندما
            نحصل على نتیجة إیجابیة ستغیر حیاتھم إلى الأبد. مثل عائلة طفل صغیر
            عالجناھا العام الماضي - في الثامنة من عمره، عانى من زیادة سریعة في
            الوزن وعلامات الاكتئاب. وصفھ والدیه بأنه طفل سعید في العادة، لكنه
            بدأ الآن غیر مھتم بأشیاء كثیرة كان یحبھا. في النھایة، قررنا أنه كان
            قصور الغدة الدرقیة، وھو بالطبع یمكن السیطرة علیھ بالأدویة. بعد مدة
            قصیرة، تأقلم الصبي جیدا مع العلاج وعاد إلى نفسھ السعیدة. لھذا أصبحت
            ممرضة ولھذا ابحث على وظیفة في طب الأطفال."
          </p>
        </div>

        <div className="q-box">
          <h3>6. ما الذي یحفزك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >

              نصیحة :
            </strong> 
            یطرح أرباب العمل ھذا السؤال لقیاس مستوى وعیك الذاتي والتأكد من أن
            مصادر التحفیز لدیك￼ تتوافق مع الوظیفة. للإجابة، كن محددا قدر
            الإمكان، قدم أمثلة واقعیة واربط إجابتك بالدور الوظیفي.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "إن إحداث فرق حقیقي في حیاة مرضاي وعائلاتھم یحفزني على السعي للتمیز
            في كل ما أقوم به. أتطلع إلى رؤیة ردود أفعال مرضاي عندما نحصل على
            نتیجة إیجابیة ستغیر حیاتھم إلى الأبد. لھذا أصبحت ممرضة ولھاذا أسعى
            للحصول على وظیفة في طب الأطفال."
          </p>
        </div>

        <div className="q-box">
          <h3>7. ما ھو شغفك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            مثل الكثیر من الاسئلة السابقة حول التحفیز، قد یسأل أصحاب العمل عما
            أنت متحمس بشأنه لفھم أفضل لما یدفعك وما تھتم به بشدة. یمكن أن
            یساعدھم ذلك على فھم ما إذا كنت مناسبا للوظیفة. للإجابة ، حدد شیئا
            أنت متحمس له حقا، واشرح سبب شغفك به، وقدم أمثلة على كیفیة سعیك وراء
            ھذا الشغف وربطه بالوظیفة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "بصفتي محترفا متمرسا وموجھا نحو خدمة العملاء ولدي أكثر من ٣ سنوات من
            الخبرة في العمل في خدمة العملاء، فإنني أزدھر في خلق بیئة ترحیبیة
            لجمیع العملاء وتقدیم خدمات عالیة الجودة. لقد ساعدني تدریبي المتخصص
            في خدمة العملاء ومھاراتي القویة في التعامل مع الآخرین على أن أصبح
            بارعا في تطویر علاقات موثوقة وطویلة الأمد تساعد في بناء قاعدة عملاء
            مخلصین. كان بعض عملائي معي منذ البدایة - أكثر من ٣ سنوات حتى الآن.
            ھذه العلاقات ھي السبب في أنني متحمس للذھاب إلى العمل كل یوم."
          </p>
        </div>

        <div className="q-box">
          <h3>8. لماذا تود ترك وظیفتك الحالیة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            ھناك أسباب عدیدة لترك الوظیفة. قم بإعداد إجابة مدروسة من شأنھا أن
            تمنح الشخص الذيً یجري المقابلة الثقة بأنك واثق بشأن تغییر الوظیفة.
            بدلا من التركیز على الجوانب السلبیة لدورك الحالي أو السابق، ركز على
            المستقبل وما تأمل في اكتسابه في منصبك التالي.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "أنا أبحث عن فرصة تمنحني القدرة على بناء علاقات أوثق وطویلة الأمد مع
            العملاء. في دوري الحالي ، تكون دورة المبیعات قصیرة جدا لدرجة أنني لا
            أقضي الكثیر من الوقت في بناء علاقة مع عملائي كما أرغب. من ھذا
            المنطلق، بناء العلاقات ھو أحد الأسباب التي دفعتني إلى اختیار مھنة في
            المبیعات وأتطلع إلى العمل مع شركة حیث تكون ھذه أولویة قصوى."
          </p>
        </div>

        <div className="q-box">
          <h3>9. ما ھي نقاط القوة لدیك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            یتیح لك ھذا السؤال التحدث عن مھاراتك الفنیة والمھارات الشخصیة.
            للإجابة، شارك الصفات￼ والسمات الشخصیة ثم اربطھا مرة أخرى بالوظیفة
            التي تجري مقابلة من أجلھا.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            أنا احل المشاكل بشكل طبیعي. أجد أنه من المفید البحث بعمق والكشف عن
            حلول للتحدیات - إنه مثل حل اللغز. إنه شيء لطالما ابدعت فیه وشيء
            أستمتع به. یتخلله الكثیر من التطویر للمنتجات لایجاد حلول مبتكرة
            للقضایا الصعبة، وھو ما جذبني إلى ھذا المسار الوظیفي في المقام
            الأول."
          </p>
        </div>

        <div className="q-box">
          <h3>10. ما ھي نقاط الضعف لدیك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            قد یكون من المحرج مناقشة نقاط ضعفك في بیئة من المتوقع أن تركز فیھا
            على إنجازاتك. ومع ذلك، عند الإجابة بشكل صحیح، یمكن أن تظھر مشاركة
            نقاط ضعفك أنك مدرك لذاتك وترغب في التحسن المستمر في وظیفتك - وھي
            سمات جذابة للغایة للعدید من أصحاب العمل. تذكر أن تبدأ بالضعف ثم ناقش
            الإجراءات التي اتخذتھا للتحسین. بھذه الطریقة، تكون قد انتھیت من
            إجابتك بشكل إیجابي.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "في وقت سابق من مسیرتي المھنیة، لاحظت أنه نظرا لأنني كنت متحمسا
            للغایة لعملي، كنت أمیل إلى قول "نعم" عندما كان ینبغي أن أقول "لا".
            في مرحلة ما انتھى بي الأمر إلى حد كبیر من عبء العمل وأخذ الكثیر من
            المشاریع والتي كانت تجبرني أن أعمل في المساء وعطلات نھایة الأسبوع.
            كان الأمر مرھقا وأثر ذلك الضغط على جودة الإنتاج. أدركت أن ھذا یأتي
            بنتائج عكسیة، لذا بدأت في تقییم عبء العمل قبل الاقرار "بنعم" او "لا"
            حسب توقعاتي لضغط العمل والنتائج المرجوة من العمل."
          </p>
        </div>

        <div className="q-box">
          <h3>11. ما ھي أھدافك المستقبلیة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            غالبا ما یسأل مدیرو التوظیف عن أھدافك المستقبلیة لتحدید ما إذا كنت
            تتطلع إلى البقاء مع الشركة على المدى الطویل أم لا. بالإضافة إلى ذلك،
            یتم استخدام ھذا السؤال لقیاس طموحك وتوقعاتك المھنیة وقدرتك على
            التخطیط للمستقبل. أفضل طریقة للتعامل مع ھذا السؤال ھي تحدید مسار
            حیاتك المھنیة الحالیة وكیف تساعدك ھذه الوظیفیة في الوصول إلى أھدافك
            النھائیة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "أرغب في مواصلة تطویر خبرتي التسویقیة على مدى السنوات العدیدة
            القادمة. أحد أسباب اھتمامي بالعمل لدى شركة ناشئة سریعة النمو مثل
            شكركتكم ھو أنه سیكون لدي القدرة على ارتداء العدید من القبعات
            والتعاون مع العدید من الأقسام المختلفة. أعتقد أن ھذه التجربة ستفیدني
            جیدا في تحقیق ھدفي النھائي المتمثل في قیادة قسم التسویق یوما ما."
          </p>
        </div>

        <div className="q-box">
          <h3>12. این ترى نفسك بعد ٥ سنوات؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            إن فھم كیف تتخیل حیاتك في المستقبل یمكن أن یساعد أصحاب العمل على فھم
            ما إذا كان مسار￼ الوظیفة والشركة یتناسب مع أھداف التنمیة الشخصیة
            الخاصة بك. للإجابة، قدم أفكارا عامة حول المھارات التي ترید تطویرھا،
            وأنواع الوظائف التي ترغب في أن تكون فیھا والأشیاء التي ترغب في
            تحقیقھا.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "في غضون خمس سنوات، أود أن أصبح خبیرا في مجالي، وقادرا على تدریب
            وإرشاد الطلاب والمصممین المبتدئین على حد سواء. أرغب أیضا في اكتساب
            خبرة متخصصة في تجربة المستخدم لأكون مساھما جیدا یعمل مع فرق التصمیم
            والتسویق في مشاریع واسعة النطاق تحدث فرقا في كل من الشركة والمجتمع."
          </p>
        </div>

        <div className="q-box">
          <h3>13. ھل یمكن أن تخبرني عن موقف عمل صعب وكیف تغلبت علیھ؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            غالبًا ما يستخدم هذا السؤال لتقييم مدى جودة أدائك تحت الضغط بالإضافة إلى قدراتك على حل المشكلات. ضع في اعتبارك أن القصص لا تُنسى أكثر من الحقائق والأرقام ، لذا حاول أن "تظهر" بدلاً من "تروي". هذه أيضًا فرصة ممتازة لإظهار جانبك الإنساني وكيف أنك على استعداد للمضي قدمًا دون أن يُطلب منك ذلك.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "كان اليوم الأول من إجازة مديري التي استمرت أسبوعين وهدد عميل وكالتنا الأعلى ربحًا بالمغادرة لأنه لم يشعر أنه كان يحصل على الخدمة الشخصية التي وعد بها. قضيت ساعة الغداء على الهاتف معه أتحدث معه عن مخاوفه. حتى أننا طرحنا أفكارًا لحملته القادمة. لقد كان ممتنًا جدًا للاهتمام الشخصي لدرجة أنه وقع عقدًا آخر مدته ستة أشهر قبل أن يعود مديري من رحلته ".
          </p>
        </div>

        <div className="q-box">
          <h3>14. ما ھو نطاق راتبك المتوقع؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            یطرح مسؤولي التوظیف ھذا السؤال للتأكد من أن توقعاتك تتماشى مع المبلغ
            الذي وضعوه في المیزانیة لھذه الوظیفة. إذا أعطیت نطاقا للراتب أقل أو
            أعلى بكثیر من القیمة السوقیة للمركز، فھذا یعطي انطباعا بأنك لا تعرف
            قیمتك. ابحث في نطاق الرواتب الخاص بالوظیفة المتقدم علیھا و تأكد من
            إعلام مدیر التوظیف إذا كنت مرنا بالنسبة للراتب.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "یتراوح راتبي المتوقع ما بین ٥٥٠٠ الى ٧٥٠٠، وھو متوسط الراتب لمرشح
            بمستوى خبرتي. ومع ذلك ، أنا مرن في ما یخص الراتب."
          </p>
        </div>

        <div className="q-box">
          <h3>15. لماذا علینا اختیارك للوظیفة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            على الرغم من أن ھذا السؤال قد یبدو كتكتیك للتخویف، إلا أن مسؤولي
            التوظیف یطرحون ھذا￼ الأمر عموما لیقدموا لك فرصة أخرى لشرح سبب كونك
            أفضل مرشح. یجب أن تتناول إجابتك المھارات والخبرات التي تقدمھا ولماذا
            تكون مناسبا للوظیفة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "لدي شغف بتطویر التطبیقات و الذي نما بشكل أقوى خلال مسیرتي المھنیة.
            أرى أن مھمة شركتكم الموقرة تتوافق مع قیمي الشخصیة، ومن خلال مقابلتي
            القصیرة معكم، یمكنني بالفعل أن أقول إن ھذه ھي الشركة الإیجابیة التي
            سأزدھر فیھا. أرید أن أعمل لدى شركة لدیھا القدرة على إعادة تشكیل
            صناعة التطبیقات وأعتقد أنكم تفعلون ذلك تماما."
          </p>
        </div>

        <div className="q-box">
          <h3>16. ھل لدیك أسئلة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            قد یكون ھذا أحد أھم الأسئلة التي تم طرحھا علیك أثناء عملیة المقابلة،
            لأنه یسمح لك باستكشاف أي موضوع لم یتم تناولھ ویظھر للمحاور أنك متحمس
            بشأن الوظیفة. بحلول ھذه المرحلة، من المحتمل أنك قد غطیت بالفعل معظم
            الأساسیات المتعلقة بالمنصب والشركة، لذا خذ وقتا لطرح أسئلة على مسؤول
            التوظیف حول تجاربه الخاصة مع الشركة واكتساب نصائح حول كیفیة النجاح
            إذا تم تعیینك.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>{" "}
            "ما الذي تحبه في العمل في ھذه الشركة؟ " "كیف سیبدو النجاح في ھذه
            الوظیفة" "ما ھي بعض التحدیات التي یواجھھا الناس عادة في ھذا المنصب؟"{" "}
          </p>
        </div>

        <div className="q-box">
          <h3>17. ما ھو أكثر شيء أعجبك في وظیفتك الأخیرة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            اربط إجابتك على ھذا السؤال باحتیاجات الشركة وركز على شرح أدائك
            المثبت في وظیفتك الأخیرة. كن محددا وقدم مثالا.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "أكثر ما أعجبني في منصبي الأخیر ھو القدرة على المساھمة بشكل تعاوني
            مع فرق أخرى. تم تشجیع كل عضو في الفریق على تقدیم أفكار جدیدة للمشروع
            والتي تم النظر فیھا باحترام من قبل الجمیع. على سبیل المثال، عملنا
            ذات مرة مع عمیل كان یعتمد علینا لحل مشكلة حرجة. التقى فریقنا لمناقشة
            الوضع. بعد أن أوصیت بخطة لحل المشكلة، أخذنا وقتا في التفكیر في
            إیجابیات الحل وسلبیاتھ، والبناء على كیفیة جعل الفكرة أفضل وأكثر
            شمولا. عندما طبقناھا، عملت بشكل أفضل وأسرع مما توقعه الجمیع. كان
            العمیل سعیدا جدا."
          </p>
        </div>

        <div className="q-box">
          <h3>18. ما ھو أقل شيء أعجبك في منصبك الأخیر؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            تجنب قول أي شيء سلبي عن صاحب العمل السابق أو المدیرین أو الزملاء.
            اجعل ھذه الإجابة￼ تتعلق بنموك الوظیفي وحماسك للانضمام إلى شكركتھم.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "بینما استمتعت بوقتي في التعلم والنمو في وظیفتي الأخیرة، كان ھناك
            نقص في الفرص بالطریقة التي أردت أن أتقدم بھا في مسیرتي المھنیة. أنا
            أستمتع بشدة بالتحدي والتحسن فیما أقوم به، وھو ما أفھم أنه یمثل
            أولویة قصوى للمدیرین في شركتكم. لھذا السبب أنا متحمس لمواصلة الحدیث
            حول ھذه الفرصة."
          </p>
        </div>

        <div className="q-box">
          <h3>19. كیف تتعامل مع التوتر وضغط العمل؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            ھذا لیس سؤالا خادعا لمعرفة ما إذا كنت ستتعرض للتوتر في العمل أم لا.
            بدلا من ذلك، فإن كیفیة تعاملك مع اللحظة العصیبة ھي مؤشر على قدرتك
            على حل المشكلات. یرغب أصحاب العمل في توظیف مرشحین یتفاعلون مع الضغط
            بشكل بناء، لذلك من المھم أن توضح إجابتك على ھذا السؤال من خلال النمو
            الشخصي.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "أستطیع أن أبقى ھادئا عندما أركز على الصورة الأكبر وأقسم مشاریعي إلى
            مھام أصغر. ما ھو الھدف النھائي الذي أحاول تحقیقھ؟ من ھذا المنطلق،
            أقوم بعمل قائمة بعناصر العمل ذات المواعید النھائیة المعقولة. حتى لو
            كان موعد المشروع الكبیر غدا، أسأل نفسي، "ما الشيء الذي یمكنني
            معالجته في الثلاثین دقیقة القادمة؟" لكي أحرز تقدما كبیرا وان لا یبدو
            ھذا المشروع ً مستحیلا."
          </p>
        </div>

        <div className="q-box">
          <h3>20. ما ھو أعظم إنجازاتك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            من السھل أن تتوقف عند اكتشاف إنجازك الأكثر إثارة للإعجاب. بدلا من
            ذلك، فكر في بعض الإنجازات التي تظھر أخلاقیات العمل وقیمك . إذا
            استطعت ، فاختر أمثلة مرتبطة أیضا بالوظیفة التي تتقدم لھا.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "في منصبي الأخیر، قمت بإدارة جمیع محتویات الوسائط الاجتماعیة. لاحظت
            أن العلامات التجاریة الأخرى كانت تجرب مقاطع الفیدیو وترى تفاعلا
            رائعا من عملائھا، لذلك سألت مدیري إذا كان بإمكاننا إجراء اختبار
            بمیزانیة منخفضة. وافق، لذلك أنتجت مقطع فیدیو داخلیا بثمن قلیل أدى
            إلى تضاعف المشاركة التي نراھا عادة على قنواتنا الاجتماعیة. كما رفعت
            نسبة المشاھدین الذین زاروا موقعنا على الویب إلى أكثر من 30٪ في غضون
            أسبوع من مشاھدة الفیدیو."
          </p>
        </div>

        <div className="q-box">
          <h3>21. ما ھي فلسفة التعلم لدیك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            ھذا لیس سؤالا فقط لمن یتقدم لشغل وظائف التدریس. قد یطلب أصحاب العمل
            ھذا من أي شخص￼ قد یقود أو یعلم الآخرین. ستحدد الإجابة الجیدة بإیجاز
            ما تعتقد أنه یجب أن یحققه التعلم وتتضمن أمثلة ملموسة لتوضیح أفكارك.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "عندما یتعلق الأمر بإدارة الأفراد، فإن فلسفتي التعلیمیة ھي أن أبدأ
            بطرح أسئلة نأمل أن ًتجعل الشخص یتوصل إلى نتیجة جدیدة بمفرده. بھذه
            الطریقة، یشعرون بالملكیة تجاه التعلم بدلا من الشعور بالإدارة
            الدقیقة. على سبیل المثال، في منصبي الأخیر، كنت أقوم بتحریر مقال كتبه
            مؤلف الإعلانات الذي قمت بإدارته. لم یكن للقصة تركیز واضح. في لقاء
            فردي، سألته عما یعتقد أنه النقطة الرئیسیة للمقال إذا كان علیه
            تلخیصھا في جملة. من ھنا، سألته عما إذا كان یعتقد أن التركیز كان
            واضحا في المقالة. لم یعتقد أنه كان واضحا وبدلا من ذلك أعتقد أنه یجب
            أن یعید صیاغةالمقدمة والخاتمة. نتیجة لذلك، تحسنت المقالة وتعلم
            المؤلف درسا كتابیا قیما قام به في عمله."
          </p>
        </div>

        <div className="q-box">
          <h3>22. ماذا تعني لك خدمة العملاء؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            إذا كنت تتقدم لوظیفة تواجه الجمھور، فقد یطرح صاحب العمل ھذا السؤال
            لمعرفة كیف تعتقد أنه یجب معاملة العملاء. تتماشى الإجابة الجیدة مع
            قیم الشركة، والتي یمكن استخلاصھا من خلال البحث في سیاسة خدمة العملاء
            الخاصة بھم، وفھم منتجاتھم وعملائھم والتأمل في تجاربك الخاصة كعمیل.
            یمكن أن تأتي إجابتك إما من منظور العمیل أو مزود خدمة العملاء.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "من واقع خبرتي، تتضمن خدمة العملاء الجیدة تحمل المسؤولیة عندما یحدث
            خطأ ما وتفعل ما في وسعك لتصحیح الأمر. على سبیل المثال، في رحلة جویة
            مؤخرا، كنت قد طلبت وجبتي مسبقا فقط لأكتشف أنھم لم یوفروا الطبق الذي
            طلبته. بدلا من مجرد ذكر الحقائق، اعتذرت المضیفة بصدق وقدمت لي مشروبا
            مجانیا و وجبة خفیفة ممتازة. بالنسبة لي، قطع ھذا الاعتذار شوطا طویلاً
            في تھدئة الأمور. كانت الھدیة الترویجیة مكافأة جعلتني أشعر بالتقدیر
            كعمیل وأختار نفس شركة الطیران لرحلتي التالیة."
          </p>
        </div>

        <div className="q-box">
          <h3>23. أخبرني عن خبرتك في العمل؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            قد یكون مسؤول التوظیف على درایة بخلفیتك وقد لا یكون كذلك. بغض النظر،
            یمنحك ھذا السؤال فرصة لمشاركة تفاصیل تجاربك الأكثر قیمة بالنسبة
            للدور المحتمل. یرید أصحاب العمل أن یعرفوا أنك تنعكس على توقعاتھم
            لمرشح مؤھل وأن لدیك مھارات ذات صلة مباشرة أو قابلة للتحویل. قدم أفضل
            الإجابات و التجارب بوضوح في سیرتك الذاتیة من حیث كیفیة إعدادك
            لمسؤولیات ھذا المنصب.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "لدي 10 سنوات من الخبرة في إدارة التمویل الشخصي، وقد ساعدت 45 عمیلا
            متكررا￼ في زیادة رأس مالھم بمعدل 15٪ كل عام. بصفتي محللا مالیا، فقد
            استخدمت مخططات النمو المرئیة للتوضیح لعملائي كیف یمكن لكل خطة ادخار
            أن تؤثر على أھدافھم. عندما أصبحت محللا مالیا أول، أشرفت على محللین
            آخرین وقمت بتدریبھم على تقدیم التجربة الأكثر فائدة لعملائنا."
          </p>
        </div>

        <div className="q-box">
          <h3>24. ماھو تعریف النجاح بالنسبة لك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            من المرجح أن تختلف إجابتك على ھذا السؤال اعتمادا على مكانك في حیاتك
            المھنیة، ولكن في أي مرحلة، تؤثر الطریقة التي تحدد بھا النجاح على
            الأھداف وكیف نقیسھا. یطلب أصحاب العمل ھذا لمساعدتھم على فھم نوع
            الموظف الذي ستكون علیھ. ستظھر الإجابة الجیدة أنك تعرف كیفیة تحدید
            الأھداف وقیاسھا وأنك على استعداد لتحدي نفسك والعمل الجاد لتحقیقھا.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "أنا أعرف النجاح على أنه إنجاز دوري في فریقي وفي الشركة. أعمل على
            إكمال واجباتي الفردیة بأكبر قدر ممكن من الفعالیة، وتحقیق التوازن بین
            ذلك والنمو المھني والمساھمة في تحقیق أھداف تنظیمیة أكبر. في منصبي
            السابق، كان النجاح یعني تجاوز المقاییس الأسبوعیة وتنفیذ العملیات
            التي دعمت مؤشرات الأداء الرئیسیة للشركة وتلبیة أھداف التطویر المھني
            الفصلیة."
          </p>
        </div>

        <div className="q-box">
          <h3>25. كیف تعمل تحت الضغط؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            ھذه ھي النسخة المباشرة للسؤال، "ھل یمكنك إخباري بموقف عمل صعب وكیف
            تغلبت علیه؟" یمكن أن یكون الأداء الجید تحت الضغط ھو الفرق بین الموظف
            العادي والموظف الممتاز.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "طوال مسیرتي المھنیة، اكتشفت كیف أتقبل العمل تحت الضغط. أجد أن ھذا
            الروتین یمكن أن یجعلنا نشعر بالرضا، لذلك أحاول البحث عن التحدیات
            التي تدفعني إلى النمو. في احد الایام، كان من المفترض أن أقدم مشروعا
            إلى عمیل في خمسة أیام. كان لدى زمیل كان یعمل مع عمیل آخر على نفس
            الموعد النھائي، لكن كان علیه أن یأخذ إجازة لأسباب شخصیة. اضطررت إلى
            تولي كلا المشروعین في نفس الوقت. بینما شعرت بإحساس أولي بالذعر،
            حاولت إعادة صیاغته كفرصة لمعرفة ما قد أكون قادرا علیه. بدلا من ترك
            التوتر یصل إلي، توصلت إلى خطة مفصلة للغایة لإدارة الوقت ووجدت طرقا
            جدیدة لتعزیز كفاءتي مما مكنني من تسلیم كلا المشروعین في الوقت
            المحدد."
          </p>
        </div>

        <div className="q-box">
          <h3>26. ماھي وظیفة أحلامك؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            یطرح أصحاب العمل ھذا السؤال عادة لأنھم یریدون التأكد من أن اھتماماتك
            وشغفك یتوافق مع￼ وظیفتھم. ستصف الإجابة الجیدة الدور الذي یتطابق مع
            الوظیفة المرشح لھا. على سبیل المثال، إذا كنت تتقدم لشغل منصب قیادي،
            فقد تناقش كیف ستتضمن الوظیفة التي تحلم بھا مسؤولیات إشرافیة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "أستمتع بتوجیه أعضاء الفریق الآخرین في المشاریع والتأكد من أن كل شيء
            یسیر بسلاسة. ستكون الوظیفة التي أحلم بھا ھي منصب قیادي حیث یكون
            أعضاء الفریق الآخرون مشاركین نشطین ویتم التواصل یومیا. أحب رؤیة
            المشروع حتى النھایة والاحتفال بالعمل الشاق للجمیع."
          </p>
        </div>

        <div className="q-box">
          <h3>27. ماذا یمكنك أن تقدم للشركة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            ھذا السؤال مشابه له، "لماذا علینا اختیارك للوظیفة؟" ستظھر الإجابة
            القویة المھارات التي یجب أن تكون ناجحا فیھا بالإضافة إلى قدرتك على
            تقدیم منظور جدید للعمل. اشرح سبب وضعك مھاراتك وخبراتك وخصائصھا بشكل
            فرید لتحقیق الأھداف الوظیفیة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "تسمح لي قدراتي في حل المشكلات تحت الضغط بشكل جید للغایة. في منصبي
            السابق بصفتي مسؤول المشتریات، كان علي أن أقرر أي الإمدادات یجب أن
            أطلبھا للبقاء في حدود المیزانیة، لكن كان لدي قدر محدود من الوقت
            لاتخاذ القرار. ولذلك أنشأت بسرعة جدول بیانات ساعدني في مقارنة أسعار
            الشركات المصنعة وتمكنت من طلب الإمدادات الضروریة في الوقت المحدد وفي
            حدود میزانیتنا. لقد استخدمت جدول البیانات طوال الفترة المتبقیة من
            وقتي مع الشركة لمساعدتھم على توفیر أكثر من 500,000. سأجلب نفس
            الاھتمام والدافع لإحداث تأثیر ھنا في شركتكم الموقرة."
          </p>
        </div>

        <div className="q-box">
          <h3>28. كیف تتعامل مع اختلاف وجھات النظر في العمل؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            یطرح أرباب العمل ھذا السؤال لقیاس كیفیة تفاعلك مع مختلف أصحاب
            المصلحة من آراء مختلفة. غالبا ما ینطوي كونك الشخص المناسب للوظیفة
            على أكثر من مجرد مھارات صعبة، فإن مدیري التوظیف أیضا یقدرون المرشحین
            الذین یمكنھم التعاون مع الآخرین والتعامل مع النزاع بطریقة مثمرة.
            ستناقش الإجابة الجیدة الوقت الذي واجھت فیه صراعا مع زمیل، وحافظت على
            الصبر لحل المشكلة وما تعلمته - كیف نشأت شخصیا ومھنیا - نتیجة
            للتجربة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "كنت أعمل كمدیر مشروع في أحد مشاریع تكنولوجیا المعلومات، وكان أحد
            الفنیین یتأخر باستمرار في إنھاء المھام. عندما تحدثت معه بشأن ذلك،
            كان رد فعله دفاعیا. حافظت على ھدوئي وسألته كیف یمكنني مساعدته في
            تحسین أدائه. ھدأ وأخبرني أنه منخرط في مشروع آخر حیث یتعین علیه
            القیام بمھام لم تكن في وصف وظیفته. بعد اجتماع مع مدیر المشروع الآخر،
            توصلنا￼ إلى قرار یخفف من عبء عمل الفني. بالنسبة لبقیة المشروع، قدم
            الفني عملاً رائعا. تعلمت أنك لا تعرف دائما ما یواجھه الآخرون، ومن
            خلال وضع ذلك في الاعتبار، یمكنني التغلب على النزاع بشكل أفضل وأن
            أكون زمیلا أكثر إفادة ودعما."
          </p>
        </div>

        <div className="q-box">
          <h3>29. لماذا أنت مھتم بھذا المنصب؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            لا یحاول القائمون على المقابلات خداعك بھذا السؤال. یریدون عادة
            التأكد من أنك تقدمت لھذه الوظیفة لأنك مھتم حقا بھا. تجنب التعبیر عن
            مخاوفك بشأن منصبك الحالي أو شركتك - غالبا ما یتم تفسیر التعلیقات
            السلبیة حول صاحب العمل الخاص بك على أنھا غیر مھنیة. ستعمل الإجابة
            الجیدة على تأطیر انتقالي بشكل إیجابي وإیصال رغبتك في النمو في
            الوظیفة التي تود الترشح لھا.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "بینماكنت أقّدر وقتي في شركتي السابقة،لم تعدھناك فرص للنمو تتوافق مع
            أھداف حیاتي المھنیة. یتناسب ھذا المنصب تماما مع مجموعة مھاراتي وكیف
            أتطلع إلى النمو في مسیرتي المھنیة. أنا أبحث أیضا عن وظیفة في شركة
            مثل شركتكم تدعم القدرات العالیة و الشغوفة. ھل یمكن أن تخبرني المزید
            عن كیفیة قیام الشركة بخلق فرص نمو للأشخاص الذین یعملون ھنا؟"
          </p>
        </div>

        <div className="q-box">
          <h3>30. ما المھارات التي ستجلبھا إلى الوظیفة؟</h3>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              نصیحة :
            </strong>
            في حین أن ھذا مشابه لأسئلة مثل، "لماذا علینا اختیارك للوظیفة؟" أو
            "ماذا یمكنك أن تقدم للشركة؟" لكنه یتیح لك أن تكون أكثر تحدیدا بشأن
            أخلاقیات العمل وأسلوبك وقدراتك الفریدة. ستستخدم الإجابة المؤثرة
            توضیح كیف یمكن لمھاراتك الفریدة أن تفید الفریق أو الشركة.
          </p>
          <p>
            <strong
              style={{ fontWeight: "bold", color: "black", fontSize: "18px" }}
            >
              - مثال :
            </strong>
            "یمكنني أن أجعل أي شخص یشعر بالراحة في بیئة جدیدة، مما یجعلني
            مناسًبا جًدا كمساعد للموارد البشریة. في منصبي السابق، جاءت إلي موظفة
            جدیدة وأخبرتني أنھا لا تعتقد أنھا مناسبة لثقافة الشركة. بعد التحدث
            معھا لبضع دقائق، أدركنا أنھا شعرت بضغط شدید للمشاركة في أحداث
            الشركة. بدأت في تقدیم الأحداث التي تضمنت عددا أقل من المسابقات
            والمزید من البیئات غیر الرسمیة، وسرعان ما أصبحت أكثر راحة مع
            فریقھا."
          </p>
        </div>
      </div> :
      <div>
         <p>لإظهار نماذج اسئلة و إجابات المقابلات الشخصية عليك اختيار باقة سنة أو 3 اشهر </p>
         <Link style={{textDecoration:'none',color:'#2784fc'}} to='/subscription'>◁ اختر باقة الآن</Link>
      </div>
      }
    </div>
  );
};

export default InterviewQ;
