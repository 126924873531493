import React from 'react'
import { Route,Redirect } from 'react-router-dom'
import CircularProgress from "@material-ui/core/CircularProgress";

// props will include a `user` object or empty object
// props will include a `component` as `Component` or a `render`
// all other props that may be passed in are `..rest`
const GuestRoute = ({
  guest,
  user,
  component: Component,
  render,
  loadingUserData,
  ...rest
}) => {          
  //  let orly = document.cookie.valueOf('orly')
  // if props include a `user` object and a `render` then create route with `render`
  if (render && guest) {
 
    return  <Route {...rest} render={render} /> 

  // if props include a `user` object but no `render` then create route with `Component`
  // if props do not include a `user` object then redirect to home
  }else {

    return <Route {...rest} render={props =>
      guest ? <Component {...props} /> : !loadingUserData && user ? <Redirect to="/myresume" /> :<div style={{height:"400px",marginTop:"100px"}}><CircularProgress color="primary"/></div> 
    } />
  }
}

export default GuestRoute
