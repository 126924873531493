import React, { useEffect, useState, Suspense } from "react";
import "./App.css";
import { Route } from "react-router-dom";
import { auth, fb } from "./firebase-config/firebaseConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import AuthenticatedRoute from "./auth/components/AuthenticatedRoute"
import InterviewQ from "./pages/interview-q/InterviewQ";
import Guest from "./pages/guest/Guest";
import GuestRoute from "./auth/components/GuestRoute";
import Header from "./header/Header";
import { getUser } from "./firestore/firestoreService";
import {DateTime,Interval} from 'luxon';
const Mycv = React.lazy(() => import("./pages/my-cv/Mycv"));
const PreviewCv = React.lazy(() => import("./pages/my-cv/PreviewCv"));
const ChooseTemplate = React.lazy(() => import("./components/ChooseTemplate"));
const EditCv = React.lazy(() => import("./components/EditCv"));
const SubPage = React.lazy(() => import("./subscription/SubPage"));
const Settings = React.lazy(() => import("./settings/Settings"));
const MobHeader = React.lazy(() => import("./header/MobHeader"));
const ForgotPass = React.lazy(() => import("./auth/components/ForgotPass"));
const SignUp = React.lazy(() => import("./auth/components/SignUp"));
const SignIn = React.lazy(() => import("./auth/components/SignIn"));
const SignOut = React.lazy(() => import("./auth/components/SignOut"));

const EditSection = React.lazy(() => import("./components/EditSection"));

const App = (props) => {

  const [user, setUser] = useState(null);
  const [guest, setGuest] = useState(null);

  const [guesttoken, setGuesttoken] = useState(null);

  const [userProviderId, setUserProviderId] = useState("");
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [refreshUser, setRefreshUser] = useState(true);

  const findUser = () => {
    auth.onAuthStateChanged((user) => {
      setLoadingUserData(true);
      if (user) {
       if(user.providerData[0]){
         setUserProviderId(user.providerData[0].providerId);
       }
        auth.currentUser
          .getIdToken(true)
          .then((idToken) => {
            if(user.isAnonymous){
              setGuest(user);
              setLoadingUserData(false);
            }else if(!user.isAnonymous){
              setGuest(null);
              getUser(user,setUser,setRefreshUser,setLoadingUserData);
            }
            setRefreshUser(false);
           
           
          })
          .catch((error) => {
            // Handle error
            console.log(error);
            setLoadingUserData(false);
          });
        // User is signed in.
      } else {
        // User is signed out.

        setLoadingUserData(false);
    
      }
    });
  };

  useEffect(() => {
    let isMount = true;
    if (isMount && refreshUser ) findUser();
    return () => (isMount = false);
  }, [refreshUser,user]);

  useEffect(() => {
    let isMount = true;
    if (isMount) fb.analytics().logEvent("app_view");
    return () => (isMount = false);
  }, []);
 


  return (
    <div className="App">

      <Suspense
        fallback={
          <div id="suspense-loader">
            <CircularProgress color="primary" />
          </div>
        }
      >
              <Header loadingUserData={loadingUserData} user={user} />
              <MobHeader loadingUserData={loadingUserData} user={user} />

        <div className="container">
        <Route
          loadingUserData={loadingUserData}
          exact
            path="/"
            render={() => <SignIn guest={guest} loadingUserData={loadingUserData} user={user} setUser={setUser} />}
          />
          <Route
          loadingUserData={loadingUserData}
          exact
            path="/sign-in"
            render={() => <SignIn guest={guest} loadingUserData={loadingUserData} user={user} setUser={setUser} />}
          />
           <Route
          loadingUserData={loadingUserData}
          exact
            path="/sign-up"
            render={() => <SignUp setRefreshUser={setRefreshUser} guest={guest} loadingUserData={loadingUserData} user={user} setUser={setUser} />}
          /> 
           <Route
          loadingUserData={loadingUserData}
          exact
            path="/forgot-password"
            render={() => <ForgotPass setUser={setUser} />}
          />
          
           {!loadingUserData && <Route
             exact
            path="/new-au"
            render={() => <Guest setRefreshUser={setRefreshUser} setLoadingUserData={setLoadingUserData} setGuest={setGuest} user={user} loadingUserData={loadingUserData} guest={guest} token={guesttoken}/>}
          />}
         <GuestRoute
          loadingUserData={loadingUserData}
          user={user}
          guest={guest}
            exact
            path="/new-au/templates/:id"
            render={(props) => <ChooseTemplate user={guest} id={props.match.params.id}/>}
          />
          <GuestRoute
          loadingUserData={loadingUserData}
          user={user}
          guest={guest}
            exact
            path="/new-au/create/new/:id"
            render={(props) => <EditCv user={guest}  id={props.match.params.id}/>}
          />
          <GuestRoute
           user={user}
            loadingUserData={loadingUserData}
            guest={guest}
            path="/new-au/resume/:id/preview"
            render={(props) => (
              <PreviewCv
              setRefreshUser={setRefreshUser}
              setUser={setUser}
              setGuest={setGuest}
                user={guest}
                cvId={props.match.params.id}
              />
            )}
          />
          
          <GuestRoute
            loadingUserData={loadingUserData}
            user={user}
            guest={guest}
            path="/new-au/edit/:id"
            render={(props) => (
              <EditSection user={guest} cvId={props.match.params.id}/>
            )}
          />


          <AuthenticatedRoute
            loadingUserData={loadingUserData}
            user={user}
            path="/myresume"
            render={() => <Mycv user={user} />}
          />
           <AuthenticatedRoute
          loadingUserData={loadingUserData}
            user={user}
            exact
            path="/resume/templates/:id"
            render={(props) => <ChooseTemplate id={props.match.params.id} user={user} />}
          />
        
          <AuthenticatedRoute
            loadingUserData={loadingUserData}
            user={user}
            path="/resume/:id/preview"
            render={(props) => (
              <PreviewCv
                user={user}
                cvId={props.match.params.id}
                setRefreshUser={setRefreshUser}
              />
            )}
          />
          <AuthenticatedRoute
            loadingUserData={loadingUserData}
            user={user}
            path="/create/new/:id"
            render={(props) => <EditCv user={user}  id={props.match.params.id}/>}
          /> 
          <AuthenticatedRoute
            loadingUserData={loadingUserData}
            user={user}
            exact
            path="/sign-out"
            render={() => <SignOut setUser={setUser} user={user} />}
          />
          <AuthenticatedRoute
          loadingUserData={loadingUserData}
            user={user}
            exact
            path="/settings"
            render={() => <Settings setLoadingUserData={setLoadingUserData} userProviderId={userProviderId} setUser={setUser} user={user} />}
          />
           <AuthenticatedRoute
            loadingUserData={loadingUserData}
            user={user}
            path="/subscription"
            render={(props) => (
              <SubPage user={user} setRefreshUser={setRefreshUser}/>
            )}
          />
           
          <AuthenticatedRoute
            loadingUserData={loadingUserData}
            user={user}
            path="/interview/questions"
            render={(props) => (
              <InterviewQ user={user} />
            )}
          />
          <AuthenticatedRoute
            loadingUserData={loadingUserData}
            user={user}
            path="/edit/:id"
            render={(props) => (
              <EditSection user={user} cvId={props.match.params.id}/>
            )}
          />
        
         

        </div>
      </Suspense>
     
    </div>
  );
};

export default App;
