export const formData = {
    contact: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      jobtitle: "",
      currentcity: "",
      address: "",
      dateofbirth: {},
      marital_status:"",
      nationality:"",
      gender:"",
      currentcountry: "",
      personalimg:"",
      supportimg:false
    },
    prosummary: "",
    experiance: [],
    education: [],
    skills: "",
    languages: [],
    trainingcourses: [],
    procertificates: [],
    references: [],
    achievements: "",
    hobbies: "",
    cvtempid: "temp22",
    cv_lang:"ar",
    printed_cv_link:"",
    owner:"",
    isAnon:false
  };