import React, { useState } from "react";
import "./Header.css";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpIcon from '@material-ui/icons/Help';

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import upgradIcon from "../images/star.svg";


const Header = ({ user, loadingUserData }) => {
  const [showSetting, setShowSetting] = useState(false);

  const unauthenticatedOptions = (
    <React.Fragment>
      <Link className="sign-up-link" to="/sign-up">
        حساب جديد
      </Link>
      <Link className="sign-in-link " to="/sign-in">
        تسجيل دخول
      </Link>
    </React.Fragment>
  );

  const authenticatedOptions = (
    <React.Fragment>
      <Link className="plansBtn" to="/subscription">
        <img style={{width:'20px',height:"20px"}} src={upgradIcon} alt="iserah"/> الباقات
      </Link>
      <Link className="nav-links" to="/myresume">
        سيرتي
      </Link>
      <Link className="nav-links" to="/interview/questions">
       المقابلات الشخصية
      </Link>
    </React.Fragment>
  );



  const settingOptions = (
    <div className="settingOptionsMenu">
       
      <Link onClick={() => setShowSetting(false)} to="/settings">
        إعدادات الحساب <SettingsIcon />
      </Link>
      <a onClick={() => setShowSetting(false)} target='_blank' rel="noopener noreferrer" href="https://iserah.com/faq/faq.html">
      المساعدة والدعم <HelpIcon />
      </a>
      <Link onClick={() => setShowSetting(false)} to="/sign-out">
        تسجيل خروج <ExitToAppIcon />
      </Link>
    </div>
  );

  return (
    <header id="header">
      <a href={user ? "/" : "https://iserah.com"}>
        <img src={logo} alt="iserah.com" />
      </a>
      {user && !loadingUserData ? (
        <div
          id="account-menu"
          onMouseOver={() => setShowSetting(true)}
          onMouseLeave={() => setShowSetting(false)}
        >
          <p id="account-menu-icons">
            <AccountCircleIcon /> مرحبا <ArrowDropDownIcon fontSize="large" />{" "}
          </p>

          {showSetting ? settingOptions : ""}
        </div>
      ) : !user && !loadingUserData ? (
        ""
      ) : (
        <CircularProgress size={30} color="primary" />
      )}

      <nav>
        {!user && !loadingUserData ? (
          unauthenticatedOptions
        ) : user && !loadingUserData ? (
          authenticatedOptions
        ) : (
          <CircularProgress size={30} color="primary" />
        )}
      </nav>
    </header>
  );
};

export default Header;
