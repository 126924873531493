import { fdb, fb,storage } from "../firebase-config/firebaseConfig";

export const createUser = (user, history, gtag_report_conversion) => {
  if (user) {
    const userObj = {
      uid: user.uid,
      isAnonymous: user.isAnonymous,
      email: user.email,
      typofsub: "",
      activation: false,
      start_date: "",
      ending_date: "",
      activationdate: "",
      deactivationdate: "",
      downloaded_count: 0,
      download_limit: 0,
      createdAt: fb.firestore.FieldValue.serverTimestamp(),
      lastLogIn: user.metadata.lastSignInTime,
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      token: "",
    };
    fdb.collection("users")
      .doc(user.uid)
      .set(userObj)
      .then(() => {
        localStorage.removeItem('anoncv');
        gtag_report_conversion();
        fb.analytics().logEvent("signed_up");
        history.push("/myresume");

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const upgradeUser = (user, history, gtag_report_conversion,setGuest,setUser,setRefreshUser,setShowAnonSignUp,cvId) => {
  if (user) {
    const userObj = {
      uid: user.uid,
      isAnonymous: user.isAnonymous,
      email: user.email,
      typofsub: "",
      activation: false,
      start_date: "",
      ending_date: "",
      activationdate: "",
      deactivationdate: "",
      downloaded_count: 0,
      download_limit: 0,
      createdAt: fb.firestore.FieldValue.serverTimestamp(),
      lastLogIn: user.metadata.lastSignInTime,
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      token: "",
    };
    fdb.collection("users")
      .doc(user.uid)
      .set(userObj)
      .then((res) => {
        const docRef = fdb.collection("users").doc(user.uid);
        localStorage.removeItem('anoncv');
        gtag_report_conversion();
        fb.analytics().logEvent("anonymous_upgrade");
        fb.analytics().logEvent("signed_up");

       return docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // setGuest(null);
            console.log('should refresh');
            setUser(doc.data());
            // setShowAnonSignUp(false);
            setRefreshUser(true);
            history.replace(`/resume/${cvId}/preview`);
            

          }
          
           
        })
      
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

 

export const deleteUser = (user,setUser) => {
  if (user) {
    const docRef = fdb.collection("users").doc(user.uid);
     docRef.delete()
      .then(() => {
        const user = fb.auth().currentUser;
        user.delete().then(() =>{
          setUser(null);

        }).catch((error) =>{
          // An error happened.
          console.log(error);
        });

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getUser = (user, setUser, setRefreshUser, setLoadingUserData) => {
  if (user.uid) {
    const docRef = fdb.collection("users").doc(user.uid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          localStorage.removeItem('anoncv');
          setUser(doc.data());
        }
        setRefreshUser(false);
        setLoadingUserData(false);
      })
      .catch((err) => {
        console.log(err);
        setRefreshUser(false);
        setLoadingUserData(false);
      });
  }
};
export const getGCV = (guestCv,history) =>{
  if (guestCv) {
    console.log('has cv');
    const docRef = fdb.collection("cvs").doc(guestCv);
    docRef.get()
      .then((cv) => {
        history.push(`/new-au/templates/${cv.id}`);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
}
export const createGCV = (formData,user,history,setLoading) =>{
  
    formData.isAnon = true;
    formData.createdAt = fb.firestore.FieldValue.serverTimestamp();
    formData.updatedAt = fb.firestore.FieldValue.serverTimestamp();
    formData.owner = user.uid
    fdb.collection("cvs").add(formData)
      .then((cv) => {
        console.log('new cv');
        localStorage.setItem('anoncv',cv.id);
        setLoading(false);
        history.push(`/new-au/templates/${cv.id}`);

      })
      .catch((error) => {
       
        console.error("Error adding document: ", error);
      });
  
};

export const createCV = (formData, user, history, setLoad) => {
  if (formData && user) {
    formData.createdAt = fb.firestore.FieldValue.serverTimestamp();
    formData.updatedAt = fb.firestore.FieldValue.serverTimestamp();
    formData.owner = user.uid
    fdb.collection("cvs").add(formData)
      .then((docRef) => {
        setLoad(false);
        history.push(`/resume/templates/${docRef.id}`)
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getAllCVs = (user, setAllCvs, setRefreshData, setLoader) => {
  if (user) {
    const docRef = fdb.collection("cvs").where("owner", "==", user.uid);
    docRef.orderBy('createdAt').get()
      .then((querySnapshot) => {
        setAllCvs(querySnapshot.docs);
        setRefreshData(false);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getCV = (user, id, setCvStyle, setCvLang, setRefreshPdfFile) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setCvStyle(cv.data().cvtempid);
        setCvLang(cv.data().cv_lang)
        setRefreshPdfFile && setRefreshPdfFile(true);

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCV = (cvId, user, setShowRemove, setRefreshData) => {
  if (cvId && user) {
    const docRef = fdb.collection("cvs").doc(cvId);
    docRef.delete()
      .then(() => {
        setRefreshData(true)
        setShowRemove(false)
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getCVContact = (user, id, setContactForm, setLoad, setRefreshData) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setContactForm({
          ...cv.data().contact
        });
        setLoad(false);
        setRefreshData(false);

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addContactInfo = (user, id, contactForm, isEditing, history, setLoad, savedData, setStep, step, save) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      contact: {
        firstname: contactForm.firstname,
        personalimg: contactForm.personalimg,
        lastname: contactForm.lastname,
        phone: contactForm.phone,
        email: contactForm.email,
        jobtitle: contactForm.jobtitle,
        currentcity: contactForm.currentcity,
        address: contactForm.address,
        dateofbirth: contactForm.dateofbirth,
        marital_status: contactForm.marital_status,
        nationality: contactForm.nationality,
        gender: contactForm.gender,
        currentcountry: contactForm.currentcountry,
        supportimg: contactForm.supportimg
      }
    })
      .then(() => {
        if (isEditing) {
          history.push(`/resume/${id}/preview`);
        } else {
          setLoad(true);
          if (step !== 5) {
            if (save) {
              savedData()
            } else {
              setStep(step + 1);
            }

          }

        }
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const addPerImg = (user, url, id,setLoadSpiner) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      'contact.personalimg':  url
      })
      .then(() => {
         setLoadSpiner(false);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteImage = (user,id,setLoadSpiner,contactForm) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      'contact.personalimg': ""
      })
      .then(() => {
        contactForm.personalimg = "";
        setLoadSpiner(false);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getCVSummary = (user,id,setSummaryForm,setLoad) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setSummaryForm({
          prosummary: cv.data().prosummary
        });
        setLoad(false);
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addSummary = (user,summaryForm, id,history,isEditing,setLoad,savedData,setStep,step,save) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      prosummary: summaryForm.prosummary
      })
      .then(() => {
        setLoad(true);
  
        if (isEditing) {
         history.push(`/resume/${id}/preview`);
       } else {
         setLoad(true);
         if (step !== 5) {
           if (save) {
             savedData()
           }else{
             setStep(step +1);
           }
    
         }
   
       }
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVSummary = (user,id,setSummaryForm,setShowRemove,setRefreshData,setLoad) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      prosummary: ""
      })
      .then(() => {
        setSummaryForm({
          prosummary: ""
          });
         setShowRemove(false);
         setRefreshData(true);
          setLoad(false);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getCVAllExperiance = (user,id,setExperiannces,setLoad,setRefreshData) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setExperiannces(cv.data().experiance)
        setLoad(false);
        setRefreshData(false)
        console.log("cv data Experiance ");

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVExperiance = (user,experianceForm,id,setLoad,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      experiance: fb.firestore.FieldValue.arrayUnion(experianceForm)
      })
      .then(() => {
        setLoad(true);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const editCVExperiance = (user, id, exId, experianceForm,showEdit,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allEx = cv.data().experiance.map(ex =>{
        if(ex.id === exId){
           ex = experianceForm
        } 
         return ex
       });
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          experiance: allEx
        });
        showEdit(undefined);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVExperiance = (user, id, exid,setShowRemove,setLoader,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allEx = cv.data().experiance.filter(ex => ex.id !== exid);
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          experiance: allEx
        });
        setShowRemove(false);
        setLoader(false);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getAllCVEducation = (user,id,setEducations,setLoad,setRefreshData) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setEducations(cv.data().education)
        setLoad(false);
        setRefreshData(false)

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVEducation = (user,educationForm, id,setLoad,setRefreshData,setShowForm) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      education: fb.firestore.FieldValue.arrayUnion(educationForm)
      })
      .then(() => {
        setLoad(true);
      setRefreshData(true);
      setShowForm(false);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const editCVEducation = (user,id, eduId, educationForm,showEdit,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allEdu = cv.data().education.map(edu =>{
        if(edu.id === eduId){
           edu = educationForm
        } 
         return edu
       });
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          education: allEdu
        });
        showEdit(undefined);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVEducation = (user,id, eduid,setShowRemove,setLoader,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allEdu = cv.data().education.filter(edu => edu.id !== eduid);
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          education: allEdu
        });
        setShowRemove(false);
        setLoader(false);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const addCVLanguage = (user,languagesForm,id,setRefreshData,setLoad,setShowOther) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      languages: fb.firestore.FieldValue.arrayUnion(languagesForm)
      })
      .then(() => {
      setLoad(false);
      setShowOther(false);
      setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getAllCVLanguage = (user,id,setLanguagesList,setRefreshData,setLoad) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setLanguagesList(cv.data().languages)
        setLoad(false);
        setRefreshData(false)

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const deleteCVLanguage = (user,id,langid,setShowRemove,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allLang = cv.data().languages.filter(lang => lang.id !== langid);
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          languages: allLang
        });
        setShowRemove(false);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getCVSkills = (user,id,setSkillsForm,setLoad,setRefreshData) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setSkillsForm({
          skills: cv.data().skills
        });
        setLoad(false);

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVSkills = (user,skillsForm,id,history,isEditing,setLoad,step,savedData,save,setStep) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      skills: skillsForm.skills
      })
      .then(() => {
        setLoad(true);
  
        if (isEditing) {
         history.push(`/resume/${id}/preview`);
       } else {
         setLoad(true);
         if (step !== 5) {
           if (save) {
             savedData()
           }else{
             setStep(step +1);
           }
    
         }
   
       }
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVSkills= (user, id,setSkillsForm,setShowRemove,setRefreshData,setLoad) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      skills: ""
      })
      .then(() => {
        setSkillsForm({
          skills: ""
          });
         setShowRemove(false);
          setLoad(false);
          setRefreshData(true);

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getCVHobbies = (user,id,setHobbiesForm,setLoad) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setHobbiesForm({
          hobbies: cv.data().hobbies
        });
        setLoad(false);
       
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVHobbies = (user,hobbiesForm, id,setLoad,setRefreshData,isEditing,history) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      hobbies: hobbiesForm.hobbies
      })
      .then(() => {
        setLoad(false);
        setRefreshData(true);
        if (isEditing) {
          history.push(`/resume/${id}/preview`);
        } 
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVHobbies = (user,id,setHobbiesForm,setRefreshData,setShowRemove,setLoad) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      hobbies: ""
      })
      .then(() => {
        setHobbiesForm({
          hobbies: ""
          });
         setShowRemove(false);
          setLoad(false);
          setRefreshData(true);

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getCVAchievements = (user,id,setAchievementsForm,setVal,setLoad) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setVal(cv.data().achievements);
        setAchievementsForm({
          achievements: cv.data().achievements
        });
        setLoad(false);

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVAchievements = (user,achievementsForm,id,setLoad,setRefreshData,isEditing,history) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      achievements: achievementsForm.achievements
      })
      .then(() => { 
        setLoad(false);
        setRefreshData(true);    
          if (isEditing) {
            history.push(`/resume/${id}/preview`);
          } 

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVAchievements = (user,id,setAchievementsForm,setRefreshData,setShowRemove,setLoad,setVal) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      achievements: ""
      })
      .then(() => {
        setVal('');
        setAchievementsForm({
          achievements: ""
          });
        setShowRemove(false);
        setLoad(false);
        setRefreshData(true);
      
         
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};


export const getAllCVRefs = (user,id,setReferences,setRefreshData,setLoad) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setReferences(cv.data().references)
        setLoad(false);
        setRefreshData(false);

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVRefs = (user,referencesForm ,id,setLoad,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      references: fb.firestore.FieldValue.arrayUnion(referencesForm)
      })
      .then(() => {
        setLoad(true);
      setRefreshData(true);
      
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const editCVRefs = (user,id, referenceId, referencesForm,showEdit,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allRef = cv.data().references.map(ref =>{
        if(ref.id === referenceId){
           ref = referencesForm
        } 
         return ref
       });
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          references: allRef
        });
        showEdit(undefined);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVRefs = (user,id,referenceId,setShowRemove,setLoader,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allRef = cv.data().references.filter(ref => ref.id !== referenceId);
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          references: allRef
        });
        setShowRemove(false);
        setLoader(false);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};


export const getAllCVProCert = (user,id,setProCertificates,setLoad,setRefreshData) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setProCertificates(cv.data().procertificates)
        setLoad(false);
        setRefreshData(false);

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVProCert = (user,proCertificatesForm,id,setLoad,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      procertificates: fb.firestore.FieldValue.arrayUnion(proCertificatesForm)
      })
      .then(() => {
        setLoad(true);
      setRefreshData(true);
      
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const editCVProCert = (user,id, proCertificateId, proCertificatesForm,showEdit,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allPCert = cv.data().procertificates.map(pCert =>{
        if(pCert.id === proCertificateId){
          pCert = proCertificatesForm
        } 
         return pCert
       });
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          procertificates: allPCert
        });
        showEdit(undefined);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVProCert = (user,id,proCertificateId,setShowRemove,setLoader,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allPCert = cv.data().procertificates.filter(pCert => pCert.id !== proCertificateId);
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          procertificates: allPCert
        });
        setShowRemove(false);
        setLoader(false);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const getAllCVTrainingAndCourses = (user,id,setTrainingAndCourses,setLoad,setRefreshData) => {
  if (id && user) {
    const docRef = fdb.collection("cvs").doc(id);
    docRef.get()
      .then((cv) => {
        setTrainingAndCourses(cv.data().trainingcourses)
        setLoad(false);
        setRefreshData(false);

      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }
};

export const addCVTrainingAndCourses= (user,trainingCoursesForm, id,setLoad,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      trainingcourses: fb.firestore.FieldValue.arrayUnion(trainingCoursesForm)
      })
      .then(() => {
        setLoad(true);
      setRefreshData(true);
      
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const editCVTrainingAndCourses = (user,id, trainCourseId, trainingCoursesForm,showEdit,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allTC = cv.data().trainingcourses.map(tC =>{
        if(tC.id === trainCourseId){
          tC = trainingCoursesForm
        } 
         return tC
       });
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          trainingcourses: allTC
        });
        showEdit(undefined);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const deleteCVTrainingAndCourses = (user,id,trainCourseId,setShowRemove,setLoader,setRefreshData) => {
  if (id && user) {
     const docRef = fdb.collection("cvs").doc(id);
     docRef.get()
      .then((cv) => {
       let allTC = cv.data().trainingcourses.filter(tC => tC.id !== trainCourseId);
        docRef.update({
          updatedAt: fb.firestore.FieldValue.serverTimestamp(),
          trainingcourses: allTC
        });
        setShowRemove(false);
        setLoader(false);
        setRefreshData(true);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const changeTemplates = (user, name, cvId, setRefreshPdfFile, history, setDownload, setDisable, setCvStyle,cvLang,onPreview) => {
  if (cvId && user) {
    const docRef = fdb.collection("cvs").doc(cvId);
    docRef.update({
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
      cvtempid: name,
      cv_lang:cvLang,
      'contact.supportimg': true
    })
      .then(() => {
        if (user.isAnonymous) {
          if(onPreview){
            setCvStyle && setCvStyle(name);
            setRefreshPdfFile && setRefreshPdfFile(true);
            setDownload(false);
            setDisable(false);
          }else{
           history.push(`/new-au/create/new/${cvId}`); 
          }
          
        } else {
          setCvStyle && setCvStyle(name);
          setRefreshPdfFile && setRefreshPdfFile(true);
          setDownload(false);
          setDisable(false);
          !setRefreshPdfFile && history.push(`/create/new/${cvId}`);

        }

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
};

export const countDownloads = (user,clickDownload,setRefreshUser) => {
  const docRef = fdb.collection("users").doc(user.uid);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        docRef
      .update({
        downloaded_count: user.downloaded_count + 1,
        updatedAt: fb.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        setRefreshUser(true);
        clickDownload();
      })
      .catch((err) => console.log(err));
  
      }
    })
    .catch((err) => {
      console.log(err);
    });
};


export const deleteAllCVs = (user,setUser) => {
  if (user) {
     const docRef = fdb.collection("cvs").where("owner", "==", user.uid);
      docRef.get()
      .then((querySnapshot) => {        
        console.log('CVs are deleted'); 
        if(querySnapshot.docs){
         querySnapshot.docs.forEach(cv =>{
          fdb.collection("cvs").doc(cv.id).delete();
        });
        deleteUser(user,setUser); 
        }else{
          deleteUser(user,setUser); 
        }
        
                 
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      }); 
     }else{
       deleteUser(user)
      console.log("will delete user");
     }
     
  
};

const deletePrImgStorage = (url,user,setUser) =>{
  const destImg = storage.refFromURL(url);
  destImg
    .delete()
    .then(() => {
      // File deleted successfully
      deleteAllCVs(user,setUser)

    })
    .catch((error) => {
      //  an error occurred!
      console.log(error);
    });
}

export const deleteAllUserDocs = (user,setUser) => {
  
  if (user) {
      const docRef = fdb.collection("cvs").where("owner", "==", user.uid);
      docRef.get()
       .then((querySnapshot) => {
         if(querySnapshot.docs.length > 0){
            querySnapshot.docs.forEach(cv =>{
          if(cv.data().personalimg){
            deletePrImgStorage(cv.data().personalimg,user,setUser)
           }else{
            deleteAllCVs(user,setUser);
          }
         
        }) 
         }else{
          deleteUser(user,setUser);
        }
          
                  
       })
       .catch((error) => {
         console.error("Error adding document: ", error);
       }); 
     
     
  }
};
