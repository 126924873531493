import React from 'react';
import './uis.css';

import CircularProgress from "@material-ui/core/CircularProgress";




const Spinner = ({sColor}) =>{




    return(
      <div style={{color: sColor ? sColor : '#2784fc'}}><CircularProgress color='inherit'/></div>
    )
}


export default Spinner