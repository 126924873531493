import React, {useCallback, useEffect, useState } from "react";
import { auth } from "../../firebase-config/firebaseConfig";
import './Guest.css';
import {withRouter,useHistory} from 'react-router-dom';
import Spinner from "../../components/uis/Spinner";
import { formData } from "../../data/CvForm";
import {  createGCV, getGCV } from "../../firestore/firestoreService";



const Guest = ({user,guest,loadingUserData,setGuest}) =>{

  const history = useHistory();
   const [loading,setLoading] = useState(false);
   

 const signGuest = useCallback(() =>{
 
     auth.signInAnonymously()
      .then((res) => {
        setGuest(res.user);
      const user = res.user
        if(user){
          createGCV(formData,user,history,setLoading);
        }

     })
    .catch((error) => {
      let errorCode = error.code;
      let errorMessage = error.message;
     console.log(errorCode,errorMessage);
    });
 
},[setGuest,history,setLoading]);
 
 
const guestCv = localStorage.getItem('anoncv');

useEffect(() => {
  let isMount = true;
  if(isMount){
    setLoading(true);
    if(user){
       history.replace('/myresume');
    }else if(!user && !guest && !guestCv){
       signGuest();
    }else if(guestCv && guest){
       getGCV(guestCv,history);
    }
 
    
  }
   return () =>{
    setLoading(false);
      isMount = false;
    };
}, [user,guest,history,signGuest,guestCv]);



    return(
      loading && <div id='guest-cont'> 
               <Spinner/>

          </div>
           
    )
}


export default withRouter(Guest)